import Vue    from 'vue';

import http   from './http'
import router from './router'
import config from './config'

import JQuery from 'jquery'
window.$ = JQuery

import App from './components/App.vue';

import 'bootstrap'
import "./styles/global.scss"

import {MediaQueries} from 'vue-media-queries';
const mediaQueries = new MediaQueries();
Vue.use(mediaQueries);

Vue.config.productionTip = false;


import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'
Vue.component(VueCountdown.name, VueCountdown);

import VueCountdown from '@chenfengyuan/vue-countdown';


Vue.use(Vuetify, {
  iconfont: 'mdi' // 'md' || 'mdi' || 'fa' || 'fa4'
})

const opts = {}

export default new Vuetify(opts)


new Vue({
    el: '#app',
    http: http,
    router: router,
    config: config,
    mediaQueries: mediaQueries,
    vuetify: new Vuetify(),
    render: h => h(App)
});