import Vue       from 'vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};

Vue.use(VueRouter)

function loadView(view) {
    return () => import(`../components/${view}.vue`);
}

const routes = [
	{ path: '/test/:uuid/:test_type', name: 'steps-builder', component: loadView('StepsBuilder'), meta: { auth: false}},

	{ path: '/404', name: 'not-found', component: loadView('Pages/NotFound'), meta: { auth: undefined } },
	{ path: '*', name: 'not-exist', component: loadView('Pages/NotFound'), meta: { auth: undefined } }
]

Vue.router = new VueRouter({
	history: true,
	mode: 'history',
	routes,
	linkActiveClass: 'active',
	scrollBehavior () {
		return { x: 0, y: 0 }
	}
})

export default Vue.router;