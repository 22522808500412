import Vue from 'vue'

import moment from 'moment'

window.moment = require('moment');
moment.updateLocale('ro', { week: { dow: 1, } })

window.moment.locale('ro', {
  relativeTime: {
    future: '+ %s',
  }
});

Vue.use(require("vue-moment"), {moment});
Vue.prototype.moment = moment;
