<template>
  <fixed-header>
    <div class="navbar">
      <div class="header">
        <img class="header-img" src="/build/images/eucom_logo_simplu.svg">
        <h2 id="countdown_header"></h2>
      </div>
    </div>
  </fixed-header>
</template>
 
<script>
import FixedHeader from 'vue-fixed-header'
// import {countdownX} from './../StepTypes/QuestionsList'
 
export default {
  components: {
    FixedHeader
  },
  mounted() {
    this.$root.$on("show_time_header", (data) => {
      let time = '';
      if(data.hide)
      {
        time = '';
      }
      else
      {
        time = 'Time: ' + '<span id="countdown_header_content">' + (data.m > 0 ? (data.m + ' m ') : '') + data.s + ' s' + '</span>';
      }
      
      this.$el.querySelector("#countdown_header").innerHTML = time;
    });
    this.$root.$on("clear_time_header", () => {
        this.$el.querySelector("#countdown_header").innerHTML = "";
    });
  }
}
</script> 
 
<style>
.navbar.vue-fixed-header--isFixed {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  background-color: white;
  z-index: 15;
}
#countdown_header_content {
  color: red;
}
</style> 