<template>
    <div class="container main" data-app>
        <FixedHeader/>
        <router-view/>
    </div>
</template>
<script>
    import  FixedHeader  from './Pages/FixedHeader';
    export default {
      data() {
        return {
        };
      },
      components: {
        FixedHeader
      },
      created() {
        
      },
      destroyed () {
      },
      watch: {
      },
      mounted() {

      },
      methods: {
      },
      computed: {}
    };
</script>